
.fileselect {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border: 2px dashed #E5E4EB;
    background-color: #FFF;
    border-radius: 20px;
    width: 100%;
    text-align: center;
    padding: 30px;
    transition: border .3s ease-in-out;

    p {
        margin: 0;
        // font-size: 14px;
        color: #262E40;

        &.filename {
            margin-bottom: 20px;
            font-weight: 700;
        }
    }

    .link {
        font-weight: 700;
        color: var(--blue);
    }

    i {
        margin: 0 auto;
        color: #262E40;
        margin-bottom: 10px;
        width: 50px;
    }
}
