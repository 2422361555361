
.ctable {
    table {
        tbody {

            tr {
                border-bottom: 1px solid var(--bg);

                td {
                    text-align: left;
                    line-height: 40px;
                    padding: 0 10px;

                    &.header {
                        font-weight: 600;
                        width: 200px;
                    }

                    span {

                        i {
                            display: block;
                            width: 25px;

                            &.true {
                                color: #27ae60;
                            }

                            &.false {
                                color: #e74c3c;
                            }
                        }
                    }

                    .label {
                        font-size: 14px;
                        padding: 2px 6px;
                        background-color: var(--blue);
                        border-radius: 6px;
                        color: #FFF;
                        font-weight: 600;
                        margin-right: 4px;

                        &.actief {
                            background-color: #27ae60;
                        }

                        &.verlopen, &.uitgeschreven {
                            background-color: #e74c3c;
                        }
                    }
                }
            }
        }
    }
}
