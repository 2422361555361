
.login-background {
    background-image: url("@/assets/background.png");
    background-color: var(--bg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    .logo {
        width: 100%;
        max-width: 200px;
        margin-bottom: 20px;
        font-size: 2rem;
    }

    .login-form-frame {
        background-color: #FAF7FC;
        border-radius: 24px;
        box-shadow: 0px 12px 30px rgba(0, 12, 40, 0.144);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 600px;

        h3 {
            margin-top: 20px;
            text-align: center;
            font-weight: 600;
        }

        h1 {
            margin-top: 20px;
            text-align: center;
            font-weight: 600;
        }

        .login-form {
            padding: 40px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 40px;
        }
    }

    .login-form-group {
        width: 100%;
        
        
        label {
            font-weight: 600;
            margin-bottom: 5px;
            display: block;
        }

        input {
            width: 100%;
            padding: 15px;
        }
        
        .inputerror{
            border-color: var(--error-color);
            background-color: #FFDADA;
        }

        .email-error {
            color: var(--error-color);
            font-size: 0.8rem;
            margin-top: 5px;
        }
    }

    .suggestion {
        text-align: center;
        font-weight: 600;
        color: #C7C7D1;
    }

    .register-button {
        color: #1C53D7;
        text-decoration: underline;
        cursor: pointer;
        text-decoration: none;
    }

    .progress-bar-container {
        margin-top: 10px;
        width: 100%;
        height: 3px;
        background-color: #F1EDF5;
    }

    .progress-bar {
        width: 0%;
        height: 100%;
        background-color: #1C53D7;
    }

    @keyframes auto-start-progress {
        0% {
            width: 0;
        }

        100% {
            width: 30%;
        }
    }

    .progress-bar.auto-start {
        animation: auto-start-progress 2s ease-in-out;
    }

    .btn {
        margin-top: 40px;
    }


}
