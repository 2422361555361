
.switchgrants {
    justify-content: flex-end;
    right: 0px;
    padding: 5px;
    border-radius: 12px;
    background-color: #FAF7FC;
    position: absolute;
    width: 220px;
    border: 2px solid transparent;

    .switchgrants-content {
        display: flex;
        align-items: center;
        padding: 0 10px;
        cursor: pointer;

        .accountname {
            color: #606673;
            font-size: 0.9em;
            font-weight: 700;
            cursor: pointer;
            margin-right: 10px;
            line-height: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 150px;
        }

        p {
            padding : 0;
            color: #1C53D7;
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;

            &.granted-text {
                position: absolute;
                bottom: 2px;
                left: 60px;
            }
        }

        i {
            width: 20px;
            height: 20px;
            float: right;
            text-align: center;
            margin-left: auto;
            cursor: pointer;
            color: var(--menutext);
        }
    }

    &:hover {
        background-color: var(--action-button);
        border: 2px solid var(--border);

        .switchgrants-content {
            color: var(--white);

            .avatar {
                border: 1px solid var(--white);
            }

            i {
                color: var(--border);
            }
        }
    }

    .account-popup {
        position: absolute;
        top: 60px;
        right: 15px;
        width: 250px;
        background-color: #FAF7FC;
        border-radius: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        padding: 10px 12px;
        border: var(--border);
        display: flex;
        flex-direction: column;
        transition: all 2s ease-in-out;

        .account-popup-item {
            list-style: none;
            margin: 0;


            h3 {
                font-family: 'Open Sans', sans-serif;
                text-wrap: wrap;
                color: #262E40;
                font-size: 0.9em;
                font-weight: 700;
                text-wrap: wrap;
            }


            .account-popup-item-content {
                display: flex;
                border-radius: 13px;
                flex-direction: row;
                padding: 5px 10px;
                align-items: center;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                background-color: var(--bg);


                .popup-account-name {
                    color: #606673;
                    font-size: 0.9em;
                    font-weight: 700;
                    cursor: pointer;
                    margin-right: 10px;
                    line-height: 15px;
                    word-wrap: break-word;
                    max-width: 150px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
                

                &:hover {
                    background-color: var(--bg);
                }

                h3{
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 0.9em;
                    font-weight: 700;
                }

                i {
                    width: 20px;
                    height: 20px;
                    margin-left: auto;
                    flex: 0 2 auto;
                }
            }

            .account-popup-item-content-active {
                background-color: var(--action-button);
                color: var(--white);
                border-radius: 13px;

                i {
                    svg {
                        stroke: var(--blue);
                        stroke-width: 2;
                    }
                }
            }

        }

        .account-popup-item-content+.account-popup-item-content {
            margin-top: 5px;
        }

        .divider {
            margin-top: 10px;
            border-top: 1px solid #eee;
        }

        .sign-out-button{
            margin-top: 10px;
            button{
                width: 100%;
                height: 40px;
                background-color: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                color: #BE0037;
                font-size: 0.9em;
                font-weight: 700;
                display: flex;
            }

            button:hover{
                background-color: var(--bg);
                border-radius: 10px;
                
            }
        }


    }

    .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        z-index: 999;
    }

    .popup-enter-active,
    .popup-leave-active {
        transition: all 0.5s ease-out;
    }

    .popup-enter-from,
    .popup-leave-to {
        transform: translateY(-10px);
        opacity: 0;
    }

    .avatar {
        width: 100%;
        height: 100%;
        max-height: 35px;
        max-width: 35px;
        border-radius: 25%;
        overflow: hidden;
        margin-right: 10px;
        flex: 0 1 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
