
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=block');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=block');
@import url('https://unpkg.com/reset-css/reset.css');

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

* {
    box-sizing: border-box;
}

:root {
    --bg: #f0f2f5; // #F1EDF5;
    --bgdark: #FFF;
    --blue: #2563EB;
    --divider: 1px solid #bdc3c755;
    --menutext: #606673;
    --action-button: #DCE1FF;
    --action-button-hover: #003BAE;
    --action-button-text: #003BAE;
    --button: #1C53D7;
    --button-hover: #2563EB;
    --font-family: 'Ubuntu', sans-serif;
    --error-color: #BE0037;
    --border: #1C53D7;
}

i {
    svg {
        vertical-align: middle;
    }
}

button {
    border: none;
    border-radius: 12px;
    background-color: #1C53D7;
    padding: 0px 15px 0px 15px;
    color: #FFF;
    height: 38px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    transition: background-color 0.3s ease-in-out;

    &:not(&:disabled) {
        cursor: pointer;
    }

    &:not(&:disabled):hover {
        background-color: #1C53D7cc;
    }

    +button {
        margin-left: 10px;
    }

    &.right {
        float: right;
    }

    &.red {
        background-color: #e74c3c;

        &:not(&:disabled):hover {
            background-color: #e74c3ccc;
        }
    }

    &.green {
        background-color: #2ecc71;

        &:not(&:disabled):hover {
            background-color: #2ecc71cc;
        }
    }

    &.light {
        background-color: #DCE1FF;
        color: #003BAE;

        &:not(&:disabled):hover {
            background-color: #DCE1FFCC;
            color: #003BAE;
        }
    }

    &:disabled {
        opacity: 0.5;
    }

    i {
        svg {
            width: 22px;
            stroke-width: 2px;
        }
    }
}

.field {
    position: relative;

    p {
        font-size: 0.9em;
        font-weight: 600;
        margin: 5px;
    }

    p.error {
        display: none;
        color: #BE0037;
    }

    &.error {
        input, textarea, select, .fileselect {
            border-color: #BE0037;
            background-color: #FFDADA;
        }

        input, textarea {
            &::placeholder {
                color: #BE003755;
                opacity: 1; /* Firefox */
            }
        }

        p.error {
            display: block;
        }
    }

    select {
        width: 100%;
    }
}

input, textarea, select {
    border: 2px solid transparent;
    border-radius: 12px;
    padding: 3px 15px;
    line-height: 30px;
    width: 100%;
    height: 40px;
    background-color: #FFF;
    color: #000;
    outline: none;

    &:focus {
        outline: none;
        border-color: #1C53D7;
        outline-offset: -2px;
    }

    &:disabled {
        color: #666;
        background-color: #FFFFFF99;
    }

}

.selectwrapper {
    position: relative;
    border: 2px solid transparent;
    border-radius: 12px;
    width: 100%;
    height: 40px;
    background-color: #FFF;

    &:after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='%23B1B1BD' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E");
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        text-align: center;
    }
}

input, textarea {

    &::placeholder {
        color: #AAA;
        opacity: 1; /* Firefox */
    }

    &:focus {
        outline: none;
        border: 2px solid #1C53D7;
        outline-offset: -2px;
    }

    &.code {
        font-family: monospace;
        font-weight: 500;
    }
}

textarea {
    height: 100px;
    resize: none;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: 'Open Sans', sans-serif;
    width: auto;

    &:not(:disabled) {
        cursor: pointer;
    }
}

label {
    display: block;
    font-weight: 500;
    margin: 10px 0;
}

.row+.row {
    margin: 15px 0;
}

.row:last-child {
    margin: 0;
}

#grid {
    display: grid;
    height: 100vh;
    grid-template-columns: 300px auto;
    grid-template-areas: "sidebar main";
    width: 100vw;
}

main {
    padding: 30px 30px 30px 0;
    height: 100%;
    overflow: scroll;
    background-color: var(--bg); 

    &.noauth {
        padding: unset;
        height: unset;
        overflow: unset;
        background-color: unset;

        .content {
            height: 100%;
            margin: unset;
            width: unset;
            max-width: unset;
        }
    }

    &.auth {
        grid-area: main;

        .content {
            height: 100%;
            position: relative;
            margin: 0 auto;

            .page {
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
    }

    h1 {
        font-family: 'Ubuntu', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 40px;
        color: #262E40;
        margin: 0;

        svg {
            margin: 0 3px 0 10px;
            width: 22px;
            stroke-width: 3px;
        }

        a {
            text-decoration: none;
            color: #262E40;
        }
    }

    h2 {
        font-family: 'Ubuntu', sans-serif;
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;
        color: #262E40;

        &.underline {
            border-bottom: var(--border);
        }

        svg {
            margin-right: 10px;
        }
    }

    h3 {
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        color: #262E40;
    }

    p {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #262E40;

        &.info {
            color: #666;
        }
    }

    section {
        padding: 24px 24px 24px 24px;
        border-radius: 15px;

        &.white {
            background-color: #FFF; //var(--bg); //#FAF7FC;
        }

        &.grey {
            border: 1px solid rgba(255, 255, 255, 1);
        }

        &.overflow {
            max-height: 100%;
            overflow: hidden;
        }

        &.scroll {
            max-height: 100%;
            overflow: scroll;
        }

        h2 {
            margin-bottom: 20px;
        }

        div + h2 {
            margin-top: 40px;

        }
    }
}

