
.otp-background {
    background-image: url("@/assets/background.png");
    background-color: var(--bg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .otp-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    .logo {
        width: 100%;
        max-width: 200px;
        margin-bottom: 20px;
        font-size: 2rem;
    }

    .otp-box-frame {
        background-color: #FAF7FC;
        border-radius: 24px;
        box-shadow: 0px 12px 30px rgba(0, 12, 40, 0.144);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 600px;

        h3 {
            margin-top: 20px;
            text-align: center;
            font-weight: 600;
        }

        h1 {
            margin-top: 20px;
            text-align: center;
            font-weight: 600;
        }

        p {
            text-align: center;
            margin-bottom: 20px;
            padding: 0 40px;
        }

        .otp-box-content {
            padding: 40px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .otp-form {
            padding: 40px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 40px;
        }
    }

    .otp-form-group {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    label {
        font-weight: 600;
        margin-bottom: 5px;
        display: block;
    }

    input {
        width: 100%;
        padding: 15px;
    }

    .suggestion {
        margin-bottom: 3vh;
        text-align: center;
        font-weight: 600;
        color: #C7C7D1;
    }

    .register-button {
        color: #1C53D7;
        text-decoration: underline;
        cursor: pointer;
        text-decoration: none;
    }

    .progress-bar-container {
        margin-top: 10px;
        width: 100%;
        height: 3px;
        background-color: #F1EDF5;

        .progress-bar {
            width: 0%;
            height: 100%;
            background-color: #1C53D7;
            transition: width 1s cubic-bezier(0.25, 0.1, 0.25, 1);
        }
    }

    .resend {
        color: #1C53D7;
        background-color: transparent;
        border: none;
        padding: 0;
        margin-bottom: 16px;
        cursor: pointer;
        display: flex;

        &:hover {
            background-color: transparent;
            .resend-icon {
                transform: rotate(180deg);
            }
        }
    }

    .resend-icon {

        transition: all 0.3s ease-in-out;
    }

    .otp-error {
        color: #FF0000;
        font-size: 0.8rem;
        margin-top: 10px;
    }
    .btn {
        margin-top: -20px;
    }
}
