
.no-templates-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // height: 70vh;
    padding: 20px 0;

    .mid-page-icon {
        color: var(--action-button);

        svg {
            width: 20vh;
        }
    }

    h3 {
        margin: 0 0 20px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

    p {
        text-align: center;
        padding: 0px 70px;
        margin-bottom: 20px;
    }
}
