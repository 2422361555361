
.rtable {
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    height: 100%;

    table {
        width: 100%;

        th,
        td {
            text-align: left;
            padding: 0px 10px;
            vertical-align: middle;
            line-height: 50px;
            height: 50px;
            line-height: 70px;
            height: 70px;

            &.boolean {
                width: 60px;

                i {
                    width: 30px;
                    display: block;
                }
            }
        }

        thead {
            font-weight: 700;

            tr {
                border-bottom: 1px solid var(--bg);

                th {
                    position: sticky;
                    top: 0;
                    background-color: #FFF;
                }
            }
        }

        tbody {
            tr {
                &.green {
                    background-color: #2ecc7111;
                }

                &.red {
                    background-color: #e74c3c11;
                }

                &.orange {
                    background-color: #f39c1211;
                }

                &.blue {
                    background-color: #1C53D711;
                }

                border-bottom: 1px solid var(--bg);

                td {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    .flex {
                        display: flex;
                        flex-direction: column;

                        span.message {
                            line-height: 15px;
                            font-size: 9px;
                            color: #e74c3c;
                            text-transform: uppercase;
                            font-weight: 700;
                        }

                        span.value {
                            line-height: 25px;
                        }
                    }

                    &.error {
                        background-color: #e74c3c33;
                        color: #e74c3c;
                    }

                    span.label {
                        font-size: 14px;
                        background-color: #EEE;
                        border-radius: 10px;
                        color: #FFF;
                        font-weight: 600;
                        padding: 0px 8px;
                        margin-right: 4px;
                        max-width: 400px;
                        display: inline-block;
                        height: 20px;
                        line-height: 20px;
                        vertical-align: middle;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &.green {
                            background-color: #00C38C;
                        }

                        &.red {
                            background-color: #BE0037;
                        }

                        &.orange {
                            background-color: #F0AD00;
                        }

                        &.blue {
                            background-color: #1C53D7;
                        }
                    }

                    .dropdown {
                        position: relative;
                        display: inline-block;
                        height: 100%;

                        select {
                            width: 300px;
                            overflow: hidden;
                        }
                    }

                    .dropdown::after {
                        content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27none%27%20viewBox%3D%270%200%2024%2024%27%20stroke-width%3D%272%27%20stroke%3D%27currentColor%27%20class%3D%27w-6%20h-6%27%3E%3Cpath%20stroke-linecap%3D%27round%27%20stroke-linejoin%3D%27round%27%20d%3D%27M19.5%208.25l-7.5%207.5-7.5-7.5%27%20%2F%3E%3C%2Fsvg%3E");
                        position: absolute;
                        top: 9px;
                        right: 8px;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        pointer-events: none;
                    }
                }
            }
        }

        &.clickable {
            tbody {
                tr {
                    cursor: pointer;

                    &:hover {
                        background-color: rgb(243, 244, 247);
                    }
                }
            }
        }
    }

    .edit-field {
        width: 100%;
        min-width: 200px;
    }
}
